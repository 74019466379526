import React from "react";
import SelectComp from "./Select";

function StoreForm({
  formData,
  handleChange,
  handleSubmit,
  viewFabrics,
  units,
  value,
  onChange,
}) {
  const handleChanged = (event) => {
    onChange(event.target.value);
  };
  return (
    <div className="d-flex justify-content-center">
      <div className="shadow-sm p-3 mb-5 bg-body rounded col-lg-6 col-sm-12">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewFabrics}
        >
          <i className="bi bi-eye"></i> &nbsp; View Fabrics
        </button>
        <br />
        <br />

        <h4 className="mb-5 text-center">Issue New Fabric</h4>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="issued_to" className="form-label">
                Issued To
              </label>
              <select
                className="form-control"
                value={value}
                onChange={handleChanged}
              >
                <option value="">Select</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.unit_name}
                  </option>
                ))}
              </select>
              {/* <SelectComp users={units} value={value} onChange={onChange} /> */}
            </div>
            <div className="mb-3">
              <label htmlFor="meters" className="form-label">
                Measurement
              </label>

              <input
                required
                autoComplete="off"
                className="form-control"
                type="number"
                placeholder="meters...."
                name="meters"
                value={formData.meters}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="for_article" className="form-label">
                For article
              </label>
              <input
                required
                autoComplete="off"
                className="form-control"
                type="text"
                placeholder="vb..."
                name="for_article"
                value={formData.for_article}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <button className=" btn btn-success" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StoreForm;
