import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ImageUploader from "../components/ImgUploadDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreForm from "../components/StoreForm";
import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import CusPopover from "../components/Popover";
import SentDeliveryDialoag from "../components/SentDeliveryDialoag";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const Fabrics = ({ authToken }) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();
  const [fabricsData, setFabricsData] = useState([]);

  const [units, setUnits] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [fabricId, setFabricId] = useState("");
  const [img, setImg] = useState("");
  const [viewSet, setViewSet] = useState(true);
  const [loading, setLoading] = useState(true);
  const [viewTable, setViewTable] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [sentPieces, setSentPieces] = useState("");
  const [stitchId, setStitchId] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const ToggleOpen = () => {
    setOpen(!open);
  };
  const ToggleOpenDelivery = (id) => {
    if (openDelivery) {
      setStitchId(null);
      setSentPieces("");
    } else {
      setStitchId(id);
    }

    setOpenDelivery(!openDelivery);
  };
  const handelSentPieces = (value) => {
    setSentPieces(value);
  };

  const [formData, setFormData] = useState({
    issued_to: "",
    meters: "",
    for_article: "",
  });
  const selectUnitId = async (value) => {
    setUnitId(value);
    console.log(value);
    setFormData({
      ...formData,
      issued_to: value,
    });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    api
      .get("stitching", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setLoading(false);
        setFabricsData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setViewTable(false);
        setSnackbar({ children: error.message, severity: "error" });
        console.error("Error fetching data:", error);
      });

    api
      .get("units/isactive", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        const options = response.data.map((item) => item.unit_name);
        setUnitNames(options);

        setUnits(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [authToken]);
  const OpenUnitDetails = (id) => {
    console.log(unitNames);
    if (id) {
      navigate(`/unitdetail/${id}`);
    }
  };
  const viewFabrics = () => {
    setViewSet((prevViewSet) => !prevViewSet);
    resetForm();
    if (fabricsData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new store",
        severity: "warning",
      });
    }
  };

  const resetForm = () => {
    setFormData({
      issued_to: "",
      meters: "",
      for_article: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`fabrics`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });

      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setTimeout(() => {
        resetForm();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Submit Failed:", error);
      setSnackbar({ children: error.message, severity: "error" });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      const response = await api.put(
        `stitching/${newRow.id}`,
        { newRow },
        {
          headers: {
            token: authToken,
          },
        }
      );

      setSnackbar({ children: response.data, severity: "success" });
    } catch (error) {
      console.error("Error making request:", error);
    }
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleOpenClick = (id) => {
    navigate(`/fabricdetail/${id}`);
  };
  const openImage = (fabric_id, imgs) => {
    setImg(imgs);
    setFabricId(fabric_id);
    ToggleOpen();
  };
  const deleteButton = (id) => async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this fabric?"
    );
    if (confirm) {
      setFabricsData(fabricsData.filter((row) => row.id !== id));
      const [row] = fabricsData.filter((row) => row.id == id);

      try {
        const response = await api.delete(`stitching/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({ children: "Deleted the data", severity: "success" });
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({
          children: error.response.data,
          severity: "error",
        });
      }
    } else {
      setSnackbar({
        children: "operation canceled on your request",
        severity: "error",
      });
    }
  };
  const SendDelivery = async () => {
    if (stitchId && sentPieces > 0) {
      try {
        const response = await api.post(
          `units/delivery`,
          { stitch_id: stitchId, sent_pieces: sentPieces },
          {
            headers: {
              token: authToken,
            },
          }
        );
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        ToggleOpenDelivery();
        setSentPieces("");
        navigate("/delivery");
      } catch (error) {
        console.error("Error making request:", error);
        setSnackbar({ children: error.response.data, severity: "error" });
      }
    } else {
      setSnackbar({
        children: "Please Add Some Quantity...",
        severity: "error",
      });
    }
  };
  const columns = [
    // {
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   field: "fabric_id",
    //   headerName: "ID",
    //   width: 130,
    //   align: "center",
    //   renderCell: (params) => (
    //     <span onClick={() => handleOpenClick(params.row.id)}>
    //       <i className="bi bi-chevron-right"></i>
    //       {params.value}
    //     </span>
    //   ),
    // },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "createdAt",
      headerName: "Date",
      width: 150,
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleDateString()}</span>
      ),

      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_img", // You can use a field that represents the image URL

      width: 70,
      headerName: "Image",

      renderCell: (params) => (
        <span onClick={() => openImage(params.row.id, params.row.product_img)}>
          <img
            src={params.value}
            style={{ width: 50, height: 50 }}
            alt="Product"
          />
        </span>
      ),
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "for_article",
      headerName: "ART#",
      width: 200,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "unit_name",
      headerName: "Unit",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: unitNames,
      align: "center",
      // renderCell: (params) => (
      //   <span onClick={() => OpenUnitDetails(params.row.unit_id)}>
      //     <i className="bi bi-chevron-right"></i>
      //     {params.value}
      //   </span>
      // ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "meters",
      headerName: "Meters",
      width: 80,
      editable: true,
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "fabric_width",
      headerName: "Width",
      width: 70,
      editable: true,
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "pieces",
      headerName: "CRP",
      width: 80,

      editable: true,
      align: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "avg",
      headerName: "AVG%",
      width: 100,

      align: "center",
      valueGetter: (params) => {
        const { pieces, meters } = params.row;
        if (pieces && meters) {
          return (meters / pieces).toFixed(2) + ",M";
        }
        return null;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "pending_delivery",
      type: "number",
      headerName: "Pending",
      width: 80,

      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "status",
      headerName: "status",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Halt",
        "Cutting",
        "Sewing",
        "Press",
        "Cheking",
        "Packing",
        "Audit",
      ],
      align: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "remarks",
      headerName: "Remarks",
      width: 150,
      editable: true,
      type: "text",
      align: "center",

      renderCell: (params) => <CusPopover cellValue={params.value} />,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 130,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deleteButton(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<LocalShippingIcon />}
            label="Dispatch"
            onClick={() => ToggleOpenDelivery(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<FileOpenIcon />}
            label="Open"
            onClick={() => handleOpenClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (!viewSet) {
    return (
      <div className="container-fluid mt-5">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

        <StoreForm
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          viewFabrics={viewFabrics}
          units={units}
          value={unitId}
          onChange={selectUnitId}
        />
      </div>
    );
  }
  return (
    <div className="container-fluid mt-1">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <ImageUploader
        key={Date.now()}
        ToggleOpen={ToggleOpen}
        open={open}
        fabricID={fabricId}
        img={img}
        authToken={authToken}
      />
      <SentDeliveryDialoag
        ToggleOpen={ToggleOpenDelivery}
        open={openDelivery}
        SendDelivery={() => SendDelivery()}
        value={sentPieces}
        onChange={handelSentPieces}
      />

      <button
        type="button"
        className="btn btn-sm btn-outline-secondary mb-1"
        onClick={viewFabrics}
      >
        <i className="bi bi-gear"></i> &nbsp;New Fabric
      </button>

      {/* <div className="d-flex justify-content-center">
        <h6 className="fw-bold shadow-sm p-2 mb-1 rounded bg-body">
          Fabrics
        </h6>
      </div> */}
      {viewTable && (
        <div style={{ height: "85vh", overflowX: "100%" }}>
          <DataGrid
            sx={{
              boxShadow: 1,
              border: 1,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "rgba(42, 255, 0, 0.5)",
              },
            }}
            loading={loading}
            dataAlign="center"
            rowHeight={30}
            rows={fabricsData}
            columns={columns}
            editMode="row"
            getRowId={(row) => row.id}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            onProcessRowUpdateError={(error) => {
              console.error("Error processing row update:", error);
              setSnackbar({ children: error.message, severity: "error" });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Fabrics;
