import { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./authContext/AuthContext";
import Products from "./pages/Products";
import Fabrics from "./pages/Fabrics";
import FabricDetail from "./pages/FabricDetail";
import Delivery from "./pages/Delivery";
import AppBarComponent from "./components/AppBarComponent";
import LoginPage from "./pages/LoginPage";
import RegPage from "./pages/RegPage";
import Users from "./pages/Users";
import Units from "./pages/Units";
// import EmbUnits from "./pages/EMBunits";
import UnitDetail from "./pages/UnitDetail";
import EmbUnitDetail from "./pages/EmbUnitDetail";

function App() {
  const { user } = useContext(AuthContext);
  const userToken = user?.accessToken;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppBarComponent authToken={userToken} />}>
            <Route
              path="/products"
              element={<Products authToken={userToken} />}
            ></Route>

            <Route
              path="/delivery"
              element={<Delivery authToken={userToken} />}
            ></Route>
            <Route
              path="/fabrics"
              element={<Fabrics authToken={userToken} />}
            ></Route>
            <Route
              path="/fabricdetail/:id"
              element={<FabricDetail authToken={userToken} />}
            ></Route>
            <Route
              path="/users"
              element={<Users authToken={userToken} />}
            ></Route>
            <Route
              path="/units"
              element={<Units authToken={userToken} />}
            ></Route>
            {/* <Route
              path="/em-units"
              element={<EmbUnits authToken={userToken} />}
            ></Route> */}
            <Route
              path="/unitdetail/:id"
              element={<UnitDetail authToken={userToken} />}
            ></Route>
            <Route
              path="/embunitdetail/:id"
              element={<EmbUnitDetail authToken={userToken} />}
            ></Route>

            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/register user"
            element={<RegPage authToken={userToken} />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
