import React, { useState } from "react";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ImageUploader = ({ ToggleOpen, open, fabricID, img, authToken }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [linkPaste, setLinkPaste] = useState("");
  const [msg, setMsg] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [linkOpen, setLinkOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 600 * 1024;
    if (selectedFile && selectedFile.size > maxSize) {
      setMsg("File size exceeds the limit (600KB)");
    } else {
      setSelectedImage(selectedFile);
      setMsg(null);
    }
  };
  const handleChangeLink = (e) => {
    setLinkPaste(e.target.value);
  };
  const ToggleLinkOpen = () => {
    setLinkOpen(!linkOpen);
  };
  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      const response = await api.put(
        `fabrics/upload/${fabricID}`,
        formData,
        {
          headers: {
            token: authToken,
          },
        },
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      setMsg(response.data);
      setSelectedImage(null);
      setTimeout(() => {
        ToggleOpen();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error, e.g., show an error message to the user
    } finally {
      setUploading(false);
    }
  };
  const handleLinkUpload = async () => {
    setUploading(true);
    try {
      const response = await api.put(
        `fabrics/upload/link/${fabricID}`,
        { product_img: linkPaste },
        {
          headers: {
            token: authToken,
          },
        }
      );
      setMsg(response.data);
      setTimeout(() => {
        ToggleOpen();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => ToggleOpen()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {`ID: ${fabricID}`} {"Upload Image"}
        </DialogTitle>

        <DialogContent>
          <img
            src={img}
            alt="Product Img"
            style={{ width: "auto", height: "425px" }}
          />
          {!linkOpen && (
            <div>
              <input
                // key={selectedImage ? selectedImage.name : "default"}
                className="mt-2 form-control"
                type="file"
                onChange={handleImageChange}
              />

              <div className="mt-2 d-flex justify-content-between">
                <div>
                  {" "}
                  <LoadingButton
                    className="mt-2"
                    size="small"
                    color="primary"
                    onClick={handleImageUpload}
                    loading={uploading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    disabled={!selectedImage || uploading}
                  >
                    <span>Save</span>
                  </LoadingButton>
                </div>
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected file"
                    style={{ width: "100px", height: "auto" }}
                  />
                )}
              </div>
            </div>
          )}

          {msg && <p>{msg}</p>}
          <div className="d-flex flex-column ">
            {linkOpen && (
              <input
                placeholder="Enter Image Link"
                className="mt-2 form-control"
                type="text"
                onChange={handleChangeLink}
                value={linkPaste}
                name="imgLink"
              />
            )}
            {linkOpen && (
              <LoadingButton
                className="mt-2"
                size="small"
                color="primary"
                onClick={handleLinkUpload}
                loading={uploading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>Save </span>
              </LoadingButton>
            )}
          </div>
          {uploading && <progress value={uploadProgress} max="100" />}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => ToggleLinkOpen()}>
            {!linkOpen ? "Add Link" : "Upload Image"}{" "}
          </Button>
          <Button onClick={() => ToggleOpen()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageUploader;
