import React, { useEffect, useState } from "react";
import axios from "axios";

import { DataGrid, GridToolbar } from "@mui/x-data-grid"; // Updated import
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const ProductsTable = ({ authToken }) => {
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [product, setProduct] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("fabrics/products", {
          headers: {
            token: authToken,
          },
        });
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setSnackbar({
          children: "Products Are Not loaded",
          severity: "error",
        });
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_image",
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img src={params.value} style={{ width: 50, height: 50 }} />
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_title",
      headerName: "Title",
      width: 500,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "total_inventory",
      headerName: "Inventory",
      width: 80,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_status",
      headerName: "Status",
      width: 80,
    },
  ];

  const handleRowClick = (params) => {
    const { row } = params;
    const { product_id } = row;
    setProduct(products.find((product) => product.product_id === product_id));
  };

  if (product) {
    return (
      <div className="mt-4">
        <div className="card position-relative">
          <button
            onClick={() => setProduct(null)}
            className="btn btn-danger position-absolute top-0 start-0 m-2"
          >
            Close
          </button>
          <img
            src={product.product_image}
            className="card-img-top"
            alt="Product"
            style={{ height: "600px", width: "450px", objectFit: "cover" }}
          />
          <div className="card-body">
            <div className="container">
              <p className="alert alert-primary row">{product.product_title}</p>

              <div className="row ">
                <p className=" btn btn-success">
                  Status: {product.product_status}
                </p>
              </div>
            </div>

            {product.variants.map((variant, index) => (
              <div
                className="btn btn-outline-secondary m-1"
                key={variant.variant_id}
              >
                <p className="">{variant.title}</p>
                <span>{variant.inventory_quantity}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="d-flex justify-content-center shadow p-2 mb-2 bg-body rounded mt-1">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <div className="" style={{ height: "90vh", overflowX: "auto" }}>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "rgba(42, 255, 0, 0.5)",
              },
            }}
            loading={loading}
            rows={products}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.product_id}
            onRowClick={handleRowClick}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
